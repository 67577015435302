import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ExtraOptions, PreloadAllModules, RouterModule} from '@angular/router';
import {MarkdownModule} from 'ngx-markdown';
import {FuseModule} from '@fuse';
import {FuseConfigModule} from '@fuse/services/config';
import {FuseMockApiModule} from '@fuse/lib/mock-api';
import {CoreModule} from 'app/core/core.module';
import {appConfig} from 'app/core/config/app.config';
import {mockApiServices} from 'app/mock-api';
import {LayoutModule} from 'app/layout/layout.module';
import {AppComponent} from 'app/app.component';
import {appRoutes} from 'app/app.routing';
import {AuthModule, AuthService, AuthHttpInterceptor} from '@auth0/auth0-angular';
import {HttpHeaders, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {FuseAlertModule} from '../@fuse/components/alert';
import {environment, environment as env} from '../environments/environment';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from "@angular/material/core";
import {ToastrModule} from "ngx-toastr";
import {firstValueFrom, forkJoin, Observable} from "rxjs";
import {catchError, map, mergeMap, tap} from "rxjs/operators";
import {APOLLO_FLAGS, APOLLO_OPTIONS, ApolloModule} from "apollo-angular";
import {HttpLink} from "apollo-angular/http";
import {NgxDiffModule} from "ngx-diff";
import {ApolloClientOptions, InMemoryCache, split} from '@apollo/client/core';
import {getMainDefinition} from "@apollo/client/utilities";
import {WebSocketLink} from "@apollo/client/link/ws";
import {MatDatepickerModule} from "@angular/material/datepicker";


const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};


export function createApollo(httpLink: HttpLink,  authService: AuthService): ApolloClientOptions<any> {
    const http = httpLink.create({
        uri: environment.auth.graphql_endpoint
    })
    const ws: WebSocketLink = new WebSocketLink({
        uri: environment.auth.ws_graphql,
        options: {
            reconnect: true,
            connectionParams: firstValueFrom(
                authService.getAccessTokenSilently().pipe(
                    map(x => {
                            return {
                                authToken: 'Bearer ' + x,
                                headers: {
                                    'Authorization': `Bearer ${x}`
                                }
                            }
                        }
                    ),
                )
            )
        }
    });

    const link = split(
        ({query}) => {
            const data = getMainDefinition(query);
            return (
                data.kind === 'OperationDefinition' && data.operation === 'subscription'
            );
        },
        ws,
        http
    )
    return {
        cache: new InMemoryCache(),
        link: link
    }

}

@NgModule({
    declarations: [
        AppComponent,

    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        AuthModule.forRoot({
            ...env.auth
        }),
        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        // 3rd party modules that require global configuration via forRoot
        MarkdownModule.forRoot({}),
        MatProgressSpinnerModule,
        MatIconModule,
        ToastrModule.forRoot({
            maxOpened: 5,
            autoDismiss: true,
            timeOut: 6000,
            closeButton: true,
            positionClass: 'toast-top-right',

        }),
        FuseAlertModule,
        ApolloModule,
        NgxDiffModule,
        MatNativeDateModule,
        MatDatepickerModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
        {
            provide: APOLLO_FLAGS,
            useValue: {
                useInitialLoading: true,
                useMutationLoading: false,
            }
        },
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink,  AuthService],
        },
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializeAppFactory,
        //     deps: [AppInitializerService],
        //     multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        }
    ]
})
export class AppModule {
}
